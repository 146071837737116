import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Modal from "../components/modal";
import AnimatedBlocks from "../components/animatedBlocks"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import MiddleBox from "../components/middleBox"

const IndexPage = props => (
  <Layout>
 
    <SEO title="Home" />
  
    <SplashContainer>
      <>

        <h1>
          Identifying Real Estate
          <br />
          Trends That Lead to Value
        </h1>
      </>
      <Img fluid={props.splashImage} />
    </SplashContainer>

    <MiddleBox>
      Essex Capital is a diversified real estate investment and development
      company that pursues opportunistic real estate transactions in
      early-stage, distressed, and value-added development projects.
    </MiddleBox>
    <AnimatedBlocks blocks={props.blocks} />
    <Img
      style={{ maxHeight: "400px", marginTop: "6rem", display: "block" }}
      fluid={props.footerImage}
    />
 {typeof document !== `undefined`? <Modal></Modal> : null}
  </Layout>
)

const SplashContainer = styled.div`
  position: relative;
  margin: 0 -2rem;
  h1 {
    position: absolute;
    z-index: 1;
    opacity: 1;
    font: 400 ${props => props.theme.fontSize.header}
      ${props => props.theme.fonts.serif};
    color: ${props => props.theme.colors.secondary};
    top: 50%;
    transform: translateY(-50%);

    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-duration: 2s;
    visibility: hidden;
    animation-fill-mode: forwards;
    animation-delay: 1s;

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 24px;
    }

    @media only screen and (max-width: 1170px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 720px) {
      margin-left: 0;
    }
  }

  @keyframes fadeInOpacity {
    from {
      visibility: hidden;
    }
    0% {
      opacity: 0;
      left: 30%;
      visibility: visible;
    }
    100% {
      opacity: 1;
      left: 2rem;
      visibility: visible;
    }
    to {
      visibility: visible;
    }
  }
`

const blocks = [
  {
    title: "About Us",
    text:
      "Essex Capital Partners Ltd. is a multi-faceted real estate investment and development company focusing on a broad range of opportunistic projects ranging from ground-up development to the repositioning of existing assets. The company’s vertically integrated platform enables it to successfully identify, acquire, master plan, develop, reposition, and manage a range of asset categories, including residential, office, retail, industrial and undeveloped land.",
    href: "/about",
    img: "images/index/block_1.jpg",
  },
  {
    title: "Capabilities",
    text:
      "Essex Capital Partners has the industry experience, skills and resources to effectively execute investment and development in a wide range of projects, from ground-up development to the repositioning of existing assets. Essex Capital’s flexibility, its experience with complex projects, and its relationships to lenders and institutions, position it for success.",
    href: "/capabilities",
    img: "images/index/block_2.jpg",
  },
  {
    title: "Case Studies",
    text:
      "Essex Capital has deep experience in ground-up development, from high-end luxury residential projects to commercial skyscrapers to suburban strip malls and rental properties. The company, which has a track record for acquiring complex real estate investments through innovative financing techniques, also has taken the lead in several master planning initiatives, bringing an integrated approach to large-scale development. Essex Capital's experience with public-private partnerships is a key differentiator for local governments seeking to collaborate with the private sector.",
    href: "/case-studies",
    img: "images/index/block_3.jpg",
  },
  {
    title: "Media",
    text:
      "Mitchell B. Rutter is an acknowledged leader in real estate investment and development.  Rutter and Essex Capital projects have been featured in all major business and real estate media in New York City and nationally. Rutter also has appeared as a speaker at a host of industry, business and educational events.",
    href: "/media",
    img: "images/index/block_4.jpg",
  },
]

export default props => {
  const data = useStaticQuery(graphql`
    query {
      splashImage: file(relativePath: { eq: "index/splash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      footerImage: file(relativePath: { eq: "index/footer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const splashImage = data.splashImage.childImageSharp.fluid
  const footerImage = data.footerImage.childImageSharp.fluid
  return (
    <IndexPage
      {...props}
      blocks={blocks}
      splashImage={splashImage}
      footerImage={footerImage}
    />
  )
}
