import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  margin: 4rem auto;
  max-width: 700px;

  p {
    font-size: ${props => props.theme.fontSize.summary};
    text-align: center;
    line-height: ${props => props.theme.lineHeight.summary};
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: ${props => props.theme.fontSize.summaryTablet};
      text-align: center;
      line-height: ${props => props.theme.lineHeight.summaryTablet};
    }
  }

  .icon {
    font-size: 50px;
    font-style: normal;
    text-align: center;
    display: block;
    margin-top: 2rem;
    position: relative;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin-top: 0;
    }
  }

  @keyframes flickerAnimation {
    /* flame pulses */
    0% {
      opacity: 0;
      top: 0;
    }
    100% {
      opacity: 1;
      top: 50px;
    }
  }
  .animate-flicker {
    opacity: 1;
    animation: flickerAnimation 2s infinite;
  }



`

// i {
//     &:before: {
//     }
// }

const MiddleBox = ({ children }) => (
  <Container>
    <p>{children}</p>
    <i className="icon animate-flicker">&#8964;</i>
  </Container>
)

export default MiddleBox
