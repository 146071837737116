import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";


const PopUpImg = () => {
  const data = useStaticQuery(graphql`
    query {
      popUpImage: file(relativePath: { eq: "banners/popup-img.png" }) { 
        childImageSharp {
          fluid(maxWidth: 448) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <Img fluid={data.popUpImage.childImageSharp.fluid} />
}


const StylePop = styled(Popup)`
 &-content{
      top:147px !important;
      width: 520px !important;
      background: #e0e8f1 !important;
      padding: 10px;
.popup-arrow{
   display:none !important;
}
}

`

const Container = styled.div(props => ({
    position: "absolute",
    top: "20px",
    zIndex: 999,
    right: '138px',
    display: 'contents'
}));



const Modal = () => {
return (
<Container>
<StylePop
    open={true}
    position="bottom right"
    repositionOnResize={true}
    closeOnDocumentClick={false}
    trigger={open => (
      <span style={{position: 'absolute', cursor: 'pointer', top: -50, right:'10%', background: "#425c6a", color: "#ffffff", padding: "5px 5px 0px 5px", height: "32px"}}> x</span>
    )}

  >
    <span> 
            <PopUpImg/>
            <p>Essex Capital Partners -- through a joint venture with AscendAmerica -- is leading a $325-million mixed use development that will transform downtown Huntsville, Alabama.</p>
    </span>
  </StylePop>
</Container>
)};

export default Modal

