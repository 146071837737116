import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const AnimatedBlocks = ({ blocks }) => (
  <BlockContainer>{blocks.map(b => renderBlock(b))}</BlockContainer>
)

const BlockContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  grid-template-rows: auto;
  grid-gap: 2% 10%;
  margin-bottom: 3rem;
  @media only screen and (max-width: 720px) {
    display: block;
  }
`

const renderBlock = block => {
  return (
    <AnimatedBlock img={block.img}>
      <h4>{block.title}</h4>
      <p>{block.text}</p>
      <div className="button">
        <Link to={block.href}>Read More</Link>
      </div>
    </AnimatedBlock>
  )
}

const AnimatedBlock = styled.div`
  color: ${props => props.theme.colors.secondary};
  overflow: hidden;
  position: relative;
  transition: color 0.3s ease-in-out 0.2s;
  padding: 2rem;
  width: 100%;

  &:hover {
    color: white;
    a {
      color: white;
      border: 1px white solid;
    }
  }
  
  &:before {
    position: absolute;
    content: '';
    z-index: -1;
    width: 100%;
    background-size: cover;
    height: 70px;
    background-image: ${props => "url(" + props.img + ")"};
    background-repeat: no-repeat;

    transition: height .6s ease-in-out;
    left: 0;
    filter: brightness(0.4);

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      background-image: none;
    }
  }

  &:hover::before {
    height: 100%;
  }
  h4 {
    padding-top: 1rem;
    font: 400 ${props => props.theme.fontSize.subheader} ${props =>
  props.theme.fonts.serif};
    color: white;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      text-align: center;
      margin-bottom: 2rem;
      color: ${props => props.theme.colors.primary};
    }
  }

  p {
    line-height: ${props => props.theme.lineHeight.paragraph};
    font: 500 ${props => props.theme.fontSize.paragraph} ${props =>
  props.theme.fonts.serif};
    min-height: 250px;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      min-height: unset;
      text-align: center;
      color: ${props => props.theme.colors.primary};
    }
  }
  
  a {
    display: inline-block;
    margin-top: 2rem;
    color: #425c6a;
    padding: .5rem .9rem;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px #425c6a solid;
    font: 100 ${props => props.theme.fontSize.small} ${props =>
  props.theme.fonts.accent};
    &:hover {
      color: #425c6a;
      background-color: white;
      border 0.2s ease-in-out 0.3s
    }

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      text-align: center;
    }
  }


  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 1rem 0rem;
    .button {
      margin: 0 auto;
      text-align: center;
    }
  }
`

export default AnimatedBlocks
